<template>

	<div>
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()"/>
				</div>
			</div>
		</template>

		<!-- Table Container Card -->
		<b-card no-body>
			<b-card-body class="desktop_one_point_five_padding">
				<b-row>
					<b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<!-- <b-form-select v-model="site" @change="filterTable();">
							<b-form-select-option value="" disabled>Select Project Site</b-form-select-option>
							<b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
						</b-form-select> -->
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="sites"
							label="site_name"
							class="w-100"
							placeholder="Project Site"
							@input="filterTable"
							v-model="site"
							:clearable=false
						>
							<template #selected-option="{ site_name }">
								<span v-if="site_name.length < 23">{{site_name}}</span>
								<span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
							</template>
						</v-select>
					</b-col>

					<!-- <b-col cols="12" md="8" class="mb-md-0 mb-1 mobile_tab_max_width_flex tab_mobile_margin_bottom_zero" v-if="checkPermission($route.name, 'All Categories')">
						<b-button variant="success" class=" mobile-margin-bottom tab_mobile_float_left float-right single_btn_remove_mrgn margin_bottom_zero_mobile" :to="{ name: 'location-category'}">

                            <span class="text-nowrap">All Categories</span>
                        </b-button>
					</b-col> -->
				</b-row>
			</b-card-body>
		</b-card>

		<b-card no-body class="mb-0">
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
						<label>Show</label>
						<v-select v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions" :clearable="false"
							class="per-page-selector d-inline-block mx-50"
							@input="filterTable"
						/>
						<label>entries</label>
					</b-col>

					<!-- Search -->
					<b-col cols="12" md="6" class="mobile_tab_max_width_flex">
						<div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
							<b-form-input v-model="searchQuery"
								class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem"
								placeholder="Search..." @input="filterTable"
							/>

							<b-button
								v-if="(checkPermission($route.name, 'Add Location')) && (this.site)"
								variant="warning"
								:to="{ name: 'add-site-locations', params: { site_id: this.site._id } }"
								class="tab_mobile_margin_bottom_zero"
							>
								<span class="text-nowrap">Add Location</span>
							</b-button>
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table sticky-header
				ref="refUserListTable"
				class="position-relative sTable"
				:items="dataList"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
				:class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
			>
				<!-- <template #cell(site)="items">
					<p v-if="items.item.site" class="wordBreak">{{ items.item.site.site_name | capitalize}}</p>         
				</template> -->
				<template #cell(info)="items">                
					<feather-icon :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon' " @click="items.toggleDetails" class="my-1"/>
				</template>
				
				<template #cell(name)="items">
					<span class="wordBreak" @click="items.toggleDetails">{{ items.item.name | capitalize}}</span>
				</template>
				
				<template #cell(status)="items">
					<b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize" @click="items.toggleDetails">
						{{ items.item.status}}
					</b-badge>
				</template>
				
				<template #cell(created_at)="items">
					<span @click="items.toggleDetails">{{sitedateTime(items.item.created_at, site.pdf_date_format, site.pdf_time_format)}}</span>
				</template>

				<template #cell(actions)="items">
					
					<!-- <b-button size="sm" @click="items.toggleDetails" class="btn btn-warning ml-1" v-if="checkPermission($route.name, 'Show Categories')">
						{{ items.detailsShowing ? 'Hide' : 'Show'}} Categories
					</b-button> -->

					<b-link v-b-tooltip.hover.v-warning title="Edit" variant="outline-warning" :to="{ name: 'edit-site-locations', params: { id: items.item._id } }" v-if="checkPermission($route.name, 'Edit')">
                        <feather-icon icon="EditIcon" class="mediumSize mr-1" />    
                    </b-link>

					<b-link v-b-tooltip.hover.v-warning :title="items.item.status == 'active' ? 'Inactive' : 'Active'" variant="outline-warning" v-if="checkPermission($route.name, 'Change Status')" @click="statusChange(items.item)">
                        <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />    
                    </b-link>

					<b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(items.item._id)" v-if="checkPermission($route.name, 'Delete')">
                        <feather-icon icon="Trash2Icon" class="mediumSize mr-1" />    
                    </b-link>

					<!-- <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" v-if="checkPermission($route.name, 'Edit') || checkPermission($route.name, 'Change Status') || checkPermission($route.name, 'Delete')">
						<template #button-content>
							<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
						</template>

						<b-dropdown-item :to="{ name: 'edit-site-locations', params: { id: items.item._id } }" v-if="checkPermission($route.name, 'Edit')">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Edit</span>
						</b-dropdown-item>

						<b-dropdown-item @click="statusChange(items.item)" v-if="checkPermission($route.name, 'Change Status')">
							<feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
							<span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
							<span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteItem(items.item._id)" v-if="checkPermission($route.name, 'Delete')">
							<feather-icon icon="Trash2Icon" />
							<span class="align-middle ml-50">Delete</span>
						</b-dropdown-item>

					</b-dropdown>  -->
				</template>

				<template #row-details="items">
					<!-- <div class="p-1 px-2" style="border: 5px solid #ff9f43 !important; border-radius: 10px">
						<b-row v-if="items.item.categories.length > 0">
							<b-col cols="12 pb-1">
								<h3 class="text-center mt-1 mb-1"><strong>Assigned Categories</strong></h3>
							</b-col>

							<div>
								<div class="card-deck text-center custom-card locCard" v-for="(category,index) in items.item.categories" :key="index">

									<div class="card box-shadow border" :class="category.selected == false ? 'border-secondary' : 'border-danger'" @click="updateSelect(items.index,index)" style="height:130px">
										
										<div class="bg-success" style="padding: 5px; width: 5px; border-radius: 25px; margin-left: auto; margin-right: 2px;margin-top:2px"/>
										<div class="w-100 text-center">
											<img :src="category.image" variant="light-primary" class="rounded text-center" style="width:40%; padding-top: 0px"/>
										</div>
										<p class="text-center mt-2 categoryH5 pb-0 mb-0 text-white" style="font-size: 11px;" :title="category.name.length > 20 ? category.name : ''"> {{category.name | truncate20}} </p>          
										
									
									</div>
								</div>

							</div>
							
						</b-row>
						<div v-else>
							<p class="text-center h5"> No Category Found. </p>
						</div>
					</div> -->
					
					<div class="px-2 fbDetail mb-1">
						<b-row v-if="items.item.categories.length > 0">
							<!-- <b-col cols="12 pb-1">
								<h3 class="text-center mt-1 mb-1"><strong>Assigned Categories</strong></h3>
							</b-col> -->

							<div class="pt-2">
								<div class="card-deck text-center custom-card locCard" v-for="(category,index) in items.item.categories" :key="index">

									<div class="card box-shadow border" :class="category.selected == false ? 'border-secondary' : 'border-danger'" @click="updateSelect(items.index,index)" style="height:130px">
										
										<div class="bg-success" style="padding: 5px; width: 5px; border-radius: 25px; margin-left: auto; margin-right: 2px;margin-top:2px"/>
										<div class="w-100 text-center">
											<img :src="category.image" variant="light-primary" class="rounded text-center" style="width:40%; padding-top: 0px"/>
										</div>
										<p class="text-center mt-2 categoryH5 pb-0 mb-0 text-white" style="font-size: 11px;" :title="category.name.length > 20 ? category.name : ''"> {{category.name | truncate20}} </p>          
										
									
									</div>
								</div>

							</div>
							
						</b-row>
						<div v-else>
							<p class="text-center h5"> No Category Found. </p>
						</div>

						<div class="pb-2">
							<b-button
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								variant="outline-secondary"
								@click="items.toggleDetails"
								size="sm"
							>
								Hide Detail
							</b-button>
						</div>

					</div>

					<!-- <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
					</b-card> -->
				</template>

			</b-table>

			<div class="mx-2 mb-2">

				<b-row>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
					</b-col>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination v-model="currentPage" :total-rows="totalRecords"
							:per-page="perPage" first-number last-number
							class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
							next-class="next-item" @input="filterTable"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18"/>
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18"/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>

			</div>

		</b-card>
	</div>
</template>

<script>
	import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
		BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb, BCardBody,
		BFormSelect, BFormSelectOption, BCardText, VBTooltip
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'
	import store from '@/store'
	import { ref, onUnmounted } from '@vue/composition-api'
	import { POST_API } from "../../../store/actions.type"
	import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
	import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

	export default {
		components: {
			BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar,BLink, BBadge,BDropdown, BDropdownItem, 
			BPagination, BBreadcrumb, vSelect, BFormSelect, BFormSelectOption, AppCollapse, AppCollapseItem, BCardText
		},
		directives: {
			'b-tooltip': VBTooltip,
		},
		data() {
			return {
				sites:[],
				site:'',

				items:[],
				isSortDirDesc: true,
				perPageOptions: [10, 20, 50, 100],
				perPage : 10,
				sortBy : 'id',
				webUrl: process.env.VUE_APP_SERVER_URL,
				totalRecords : 0,
				currentPage : 1,
				searchQuery:'',
				from:null,
				to:null,
				
				tableColumns: [
					// { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '20%'}},
					{ key: 'info', label:'INFO',sortable: false , thStyle:  {width: '5%'}},
					{ key: 'name', label: 'Location Name', sortable: true , thStyle:  {width: '25%'}},
					{ key: 'status', label: 'Status', sortable: true , thStyle:  {width: '18%'}},
					{ key: 'created_at', label: 'Added On', sortable: false , thStyle:  {width: '17%'}},
					{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
				],
			}
		},

		methods : {
			variantColor(status){
				if (status == 'active') {
					return 'success';
				}else{
					return 'primary';
				}
			},

			dataList(){
				if(this.site){
					return this.$store.dispatch(POST_API, {
						data:{
							page          : this.currentPage,
							keyword       : this.searchQuery,
							rowsPerPage   : this.perPage,
							sortBy 		  : this.sortBy,
							sortDirection : this.isSortDirDesc,
							site_id       : this.site ? this.site._id : null,
							location
						},
						api: '/api/location-list-card'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;
						} else {
							this.items = this.$store.getters.getResults.data.docs;
							this.totalRecords = this.$store.getters.getResults.data.total;
							this.perPage = this.$store.getters.getResults.data.limit;
							this.from = this.$store.getters.getResults.data.from
							this.to = this.$store.getters.getResults.data.to

							return this.items;
						}
					});
				}
			},

			filterTable(){
				this.$refs.refUserListTable.refresh();
			},

			allSites(){
				return this.$store.dispatch(POST_API, {
					data:{
						role:this.$store.getters.currentUser.role,
						om_sites:this.$store.getters.currentUser.om_sites,
					},
					api: '/api/all-sites'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						this.sites = this.$store.getters.getResults.data;
						// if (this.sites.length == 1 ) {
						// 	this.site = this.sites[0]._id;
						// }

						this.site = this.defaultSite(this.sites);
						this.filterTable();
						
						return this.sites;
					}
				});
			},

			statusChange(item){
				if (item.status == 'active') {
					var msg = 'Are you sure want to inactivate this record?';
				} else{
					var msg = 'Are you sure want to activate this record?';
				}

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id: item._id,
								status:item.status
							},
							api:"/api/change-location-status",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {
								this.successAlert();
								this.$refs.refUserListTable.refresh();
							}
						});
					}
				})
			},

			deleteItem(id){
				var msg = 'Are you sure want to delete this record?';

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id: id,
								status:'deleted'
							},
							api:"/api/change-location-status",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {
								this.successAlert();
								this.$refs.refUserListTable.refresh();
							}
						});
					}
				})
			},

			updateSelect(locationIndex, categoryIndex){
				/*console.log(locationIndex,categoryIndex);
				console.log(this.items[locationIndex])

				console.log(this.items[locationIndex].categories[categoryIndex])*/

				return this.$store.dispatch(POST_API, {
					data:{
						site: this.items[locationIndex].site._id,
						location:this.items[locationIndex]._id,
						category:this.items[locationIndex].categories[categoryIndex]._id,
						selected:this.items[locationIndex].categories[categoryIndex].selected
					},
					api:"/api/assign-category-from-location",
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showAlert();
					} else {
						var data = this.$store.getters.getResults.data;

						if (this.items[locationIndex].categories[categoryIndex].selected == true) {
							this.items[locationIndex].categories[categoryIndex].selected = false;
						}else{
							this.items[locationIndex].categories[categoryIndex].selected = true;
						}

					}
				});
			},

			breadCrumb(){
				var item = [{
					to:{name:'client-dashboard'},
					text: 'Dashboard',
				},{
					to:null,
					text: 'Feedback System',
				},{
					to:null,
					text: 'Settings',
				},{
					to:null,
					text:'Location',
					active:true
				}];
				return item;
			}
		},

		mounted(){
			this.allSites();
		}	
	}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
	.border-danger {
		border: 2px solid #ea5455 !important;
		padding: 2px !important;
	}
	.border-secondary {
		border: 2px solid rgb(221, 221, 221) !important;
		padding: 2px !important;
	}
	.card-body{
		padding-top: 1rem !important;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>
